@import '../Variables';

/* -------------------------------------------------------------------------- */
/*                                   HEADER                                   */
/* -------------------------------------------------------------------------- */

.header-wrapper {
	background: $white;
	box-shadow: 0px 2px 24px rgba($black, 0.25);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
}

.header-nav,
.header-nav-inner {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.header-nav {
	padding: 0.75rem 0 .5rem;
	grid-template-columns: 1fr auto 1fr;
	gap: 3rem;

	.header-nav-inner {
		flex-basis: 1fr;
		width: 100%;
		gap: .5rem;

		&.header-nav-inner--left {
			// justify-content: end;
		}

		&.header-nav-inner--right {
			// justify-content: start;
		}
	}

	.header-logotype {
		width: auto;
	}
}

.header-logotype {
	display: inline-block;
	text-align: center;
	margin: 0;
	height: 1.5rem;
}

.nav-item {
	padding: .5rem .75rem .35rem .75rem;
	line-height: 1.25em;
	display: inline-flex;
	background-color: $white;
	border-radius: .5rem;
	border: 1px solid $white;

	&:hover {
		background-color: $gloss;
		border-color: $gloss;
	}
}

@media screen and (max-width: 1200px) and (min-width: 901px) {

	.header-nav {
		gap: 1rem;

		.header-nav-inner {
			gap: 0;
		}
	}

	.nav-item,
	.nav-button.button {
		font-size: .825rem;
	}

	.header-logotype {
		height: 1.25rem;
	}
}

@media screen and (max-width: 900px) {

	.header-nav {
		grid-template-columns: auto 1fr;

		.header-nav-inner.header-nav-inner--right {
			justify-content: end;
		}
	}

	.nav-item,
	.header-nav-inner--left {
		display: none;
	}
}

@media screen and (min-width: 901px) {

	.header-logotype {
		// transform: translateY(-8%);
	}
}