/* -------------------------------------------------------------------------- */
/*                                   COLORS                                   */
/* -------------------------------------------------------------------------- */

$white: #fff;
$black: #2D2322;

$daisy: #F6DF8D;
$goldenrod: #F6A435;
$burntedge: #CD6F23;
$gloss: #E1EAE6;
$foam: #C3D5CD;
$teal: #488180;
$stormysea: #285B62;


/* -------------------------------------------------------------------------- */
/*                                 TYPOGRAPHY                                 */
/* -------------------------------------------------------------------------- */

@font-face {
    font-family: 'JustCosmic';
    src: local('JustCosmic'), 
         url('./resources/JustCosmic.woff2') format('woff2'),
         url('./resources/JustCosmic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@mixin heading-font-family {
	font-family: 'JustCosmic', Garamond, serif;
    font-weight: normal;
    font-style: normal;
}

@mixin body-font-family {
	font-family: 'minion-3', Garamond, serif;
	font-weight: 400;
	font-style: normal;
}

@mixin mono-font-family {
	font-family: "fira-mono", source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	font-weight: 400;
	font-style: normal;
}

@mixin heading-text {
	@include heading-font-family;
	font-style: normal;
	font-weight: 400;
	line-height: 115%;
	letter-spacing: 0.03em;
	color: $black;
}