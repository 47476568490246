@import '../Variables';

/* -------------------------------------------------------------------------- */
/*                                JOIN DISCORD                                */
/* -------------------------------------------------------------------------- */

$blurple: #5865F2;
$light-blurple: #E0E3FF;
$black: #000;


@font-face {
    font-family: 'GintoNord';
	src: local('GintoNord'), 
		url('../resources/GintoDiscordNord.woff') format('woff'),
		url('../resources/GintoDiscordNord.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Ginto';
	src: local('Ginto'), 
		url('../resources/ABCGintoDiscord-Regular.woff') format('woff'),
		url('../resources/ABCGintoDiscord-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

.join-discord-wrapper {
	display: inline-block;
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	font-size: .85em;
	border-radius: 1.25rem;
	background-color: $blurple;
	border: 1px solid $white;
	max-width: 15em;
	box-shadow: 0px 8px 24px rgba($black, 0.25);
	transition: 0.33s cubic-bezier(0.34, 1.56, 0.64, 1);
    z-index: 1010;

	&.join-discord-wrapper--off-screen {
		right: -15em;
	}

	* {
		color: $white;
		font-family: 'GintoNord', Helvetica, sans-serif;
		font-weight: normal;
		font-style: normal;
	}

	.join-discord-inner {
		padding: 1em;
	}

	.join-discord-title {
		text-align: center;
		margin-bottom: .75em;
		font-size: 1em;
	}

	.join-discord-desc {
		font-family: 'Ginto', Helvetica, sans-serif;
		font-weight: normal;
		font-style: normal;
		font-size: 1em;
		text-align: center;
		margin-bottom: 1em;
	}

	.button.join-discord-button {
		display: flex;
		text-align: center;
		background-color: $black;
		border-color: $black;
		color: $white;
		box-shadow: none;
		align-items: center;
		font-size: 1em;

		&:not(:hover) {
			.discord-button-logo--blurple {
				display: none;
			}
		}

		&:hover {
			background-color: $white;
			border-color: $white;
			color: $blurple;

			.discord-button-logo--white {
				display: none;
			}
		}
	}

	.discord-button-logo {
		display: inline-block;
		position: relative;
		height: 1.25em;
		width: auto;
		margin-right: 1em;
	}

	.join-discord-close {
		position: absolute;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		top: -.5em;
		right: -.5em;
		background-color: $blurple;
		border: 1px solid $white;
		border-radius: 100em;
		width: 1.5em;
		height: 1.5em;
		padding: .25em;

		&:hover {
			background-color: $black;
		}
	}
}

