@import '../Variables';

.slider-wrapper {
	position: relative;

	.slider-inner {
		max-width: none;
		display: grid;
		grid-auto-columns: 25%;
		grid-auto-flow: column;
		margin: 0 1rem;
		overflow-x: auto;
		overflow-y: hidden;

		&>* {
			padding-left: .5rem;
			padding-right: .5rem;
		}

		@media screen and (max-width: 900px) {
			grid-auto-columns: 33.33%;

			&>* {
				padding-left: .75rem;
				padding-right: .75rem;
			}
		}

		@media screen and (max-width: 680px) {
			grid-auto-columns: 50%;
		}
	}
}

.slider-nav {
	display: inline-flex;
	position: absolute;
	width: 4rem;
	height: 4rem;
	top: 33%;
	transform: translateY(-50%);
	align-items: center;
	justify-content: center;
	background-color: $daisy;
	border-radius: 100em;
	overflow: hidden;
	cursor: pointer;
	transition: .15s;

	&.slider-nav--right {
		right: 0;
	}

	&.slider-nav--left {
		left: 0;
	}

	&:not(.slider-nav--disabled) {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

		&:hover {
			background-color: $goldenrod;
		}
	}

	&.slider-nav--disabled {
		opacity: .5;
		pointer-events: none;

		.slider-nav-img {
			opacity: .66;
		}
	}
}