@import '../Variables';

/* -------------------------------------------------------------------------- */
/*                                 VIDEO CARD                                 */
/* -------------------------------------------------------------------------- */

.video-card-img-wrapper {
	position: relative;
}

.video-card-img {
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	padding-bottom: 56.25%;
	border: 1px solid $stormysea;
	border-radius: .5rem;

	&:before,
	&:after {
		content: '';
		position: absolute;
		width: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.08) 40%, rgba(0, 0, 0, 0) 100%);
		transition: .15s;
	}

	&:before {
		height: 100%;
		background: rgba($teal, 0.12);
		opacity: 0;
		z-index: 110;
	}

	&:after {
		height: 3rem;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.08) 40%, rgba(0, 0, 0, 0) 100%);
		z-index: 100;
	}
}

.video-card-download-img {
	margin-left: .75rem;
	z-index: 120;
}


.video-card-hover-play {
	position: absolute;
	display: inline-flex;
	width: 5rem;
	height: 5rem;
	border-radius: 100em;
	padding: 1rem;
	top: 50%;
	left: 50%;
	align-items: center;
	justify-content: center;
	transform: translate(-50%, -40%); //intentially down so it animates up
	background-color: $white;
	box-shadow: 0px 8px 20px rgba($stormysea, 0.25);
	opacity: 0;
	transition: .15s;
}

.video-card-img-wrapper {
	&:hover {
		.video-card-hover-play {
			opacity: 1;
			transform: translate(-50%, -50%);
		}

		.video-card-img {
			&:before {
				opacity: 1;
			}

			&:after {
				// opacity: 0;
			}
		}
	}
}